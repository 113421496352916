import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AngularFireModule } from '@angular/fire';
import { environment } from '../../environments/environment';
import { AngularFirestoreModule, FirestoreSettingsToken } from '@angular/fire/firestore';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireFunctionsModule, ORIGIN, FUNCTIONS_REGION, REGION } from '@angular/fire/functions';
import { NbDialogModule, NbIconLibraries, NbMenuModule, NbThemeModule, NbToastrModule } from '@nebular/theme';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import { MarkdownModule, MarkedOptions } from 'ngx-markdown';
import { EasymdeModule } from 'ngx-easymde';

const markdownEditorOptions = {
  markedOptions: {
    provide: MarkedOptions,
    useValue: {
      breaks: true,
    }
  }
};

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    // Firebase Modules
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireAuthModule,
    AngularFireStorageModule,
    AngularFireFunctionsModule,
    // Nebular Modules
    NbThemeModule.forRoot({ name: 'linkk' }),
    NbDialogModule.forRoot(),
    NbToastrModule.forRoot(),
    NbMenuModule.forRoot(),
    NbEvaIconsModule,
    // Markdown & Editor
    MarkdownModule.forRoot(markdownEditorOptions),
    EasymdeModule.forRoot({})
  ],
  providers: [
    // TODO: Bring cloud functions to europe
    // { provide: ORIGIN, useValue: 'https://linkk-c5e27.web.app' },
    // { provide: REGION, useValue: 'europe-west3' },
    // { provide: FUNCTIONS_REGION, useValue: 'europe-west3' },
    // Uncomment the following lines to use the emulators for Firebase Cloud Functions and Firestore:
    // { provide: ORIGIN, useValue: 'http://localhost:5001' },
    // { provide: FirestoreSettingsToken, useValue: { host: 'localhost:8080', ssl: false }}
  ]
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule, private iconLibraries: NbIconLibraries) {
    if (parentModule) {
      throw new Error('CoreModule has already been loaded. It should only be loaded once in the AppModule.');
    }
    this.iconLibraries.registerFontPack('font-awesome', { packClass: 'fa', iconClassPrefix: 'fa' });
  }
}
