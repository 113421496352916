import { ErrorHandler, Injectable } from '@angular/core';
import * as Sentry from '@sentry/browser';
import { environment } from '../../../environments/environment';
import { NbGlobalPhysicalPosition, NbToastrConfig, NbToastrService } from '@nebular/theme';

Sentry.init(environment.sentry);

@Injectable({ providedIn: 'root' })
export class SentryErrorHandler implements ErrorHandler {

  constructor(private toastrService: NbToastrService) { }

  handleError(error) {
    console.error('ERROR:', error);
    const eventId = Sentry.captureException(error.originalError || error);
    // Sentry.showReportDialog({ eventId });
    // this.triggerErrorAlert(error);
  }

  private triggerErrorAlert(error: Error) {
    const toastConfig: Partial<NbToastrConfig> = {
      position: NbGlobalPhysicalPosition.TOP_RIGHT,
      duration: 0,
      destroyByClick: true,
      status: 'danger',
      preventDuplicates: true
    };
    // tslint:disable-next-line:max-line-length
    const message = `We are sorry, but an error occurred: ${error.message} Please try again or use the feedback button to contact our support team.`;
    this.toastrService.show(message, 'Something went wrong', toastConfig);
  }

}
